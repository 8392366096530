import React from 'react';

import {
  DynamicContentComponent
} from '@lerna-monorepo/common/components/dynamicContentComponent/dynamicContentComponent.component';
import { Color } from '@lerna-monorepo/common/theme';
import { ProductPageHeader } from '@lerna-monorepo/common/components/productPageHeader/productPageHeader.component';
import { DynamicContent } from '@lerna-monorepo/common/interfaces/dynamicContent.types';
import { renderWhenTrue } from '@lerna-monorepo/common/utils';

import {
  DynamicContentComponentContainer,
  LocalCaseStudySection,
  RelatedCaseStudyRelatedProductsContainer,
  DynamicContentGroupContainer,
  HalfBox,
  LocalProductsSection,

} from './singleProduct.styled';
import { SingleProductPageData } from './singleProduct.types';

const SingleProductPage: React.FC<SingleProductPageData> = (pageData: SingleProductPageData) => {
  const {
    data,
    currentLanguage,
    singleProductSettings,
    caseStudyBasePath,
    productsBasePath,
    caseStudiesBasePaths,
  } = pageData.pageContext;
  const {
    productHeaderSection,
    productContent,
    relatedCaseStudies,
    relatedProducts,
  } = data.acf;
  const {
    productImages,
    header,
    subheader,
    productDescription,
    leftButton,
    rightButton,
  } = productHeaderSection;

  const renderContent = () => productContent.map((productContentItem: DynamicContent) => (
    <DynamicContentComponentContainer
      key={`${productContentItem.header.bigHeader.mainContent}${productContentItem.header.bigHeader.descriptionContent}`}
    >
      <DynamicContentComponent
        header={productContentItem.header}
        dynamicContent={productContentItem.dynamicContent}
        currentLanguage={currentLanguage}
        pageId={data.slug}
      />
    </DynamicContentComponentContainer>
  ));

  const renderCaseStudySection = renderWhenTrue(() => (
    <LocalCaseStudySection
      header={singleProductSettings.singleProductRelatedCaseStudiesSettings.header}
      caseStudyBoxes={relatedCaseStudies}
      labelBackgroundColor={Color.veryLightGrey2}
      boxesBaseUrl={caseStudyBasePath}
      currentLanguage={currentLanguage}
      basePaths={caseStudiesBasePaths}
    />
  ));

  const renderProductSection = renderWhenTrue(() => (
    <LocalProductsSection
      header={singleProductSettings.singleProductRelatedProducts.header}
      description={singleProductSettings.singleProductRelatedProducts.description}
      products={relatedProducts}
      boxesBaseUrl={productsBasePath}
      currentLanguage={currentLanguage}
    />
  ));

  return (
    <>
      <ProductPageHeader
        productImages={productImages}
        header={header}
        subheader={subheader}
        productDescription={productDescription}
        leftButton={leftButton}
        rightButton={rightButton}
        currentLanguage={currentLanguage}
      />
      <DynamicContentGroupContainer>
        {renderContent()}
        <HalfBox />
      </DynamicContentGroupContainer>
      <RelatedCaseStudyRelatedProductsContainer>
        {renderCaseStudySection(!!relatedCaseStudies)}
        {renderProductSection(!!relatedProducts)}
      </RelatedCaseStudyRelatedProductsContainer>
    </>
  );
};

export default SingleProductPage;
